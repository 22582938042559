import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import './SiteUsers.css';

const SiteUsersInfo = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    axios.post("https://Business.ozchest.com/getAllUsers",{isOzchest:true}).then((res) => {
      if (res) {
        setUsers(res.data.users);
      }
    });
  }, []);

  const handleDeleteUser = (_id) => {
    const data = {
      user: _id
    };
    axios.post("https://Business.ozchest.com/deleteSiteUser", data).then((res) => {   
      if (res) {
        setUsers((prevUsers) => prevUsers.filter(user => user._id !== _id));
        toast.success('Successfully deleted user');
      } 
    });
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalIsOpen(false);
  };

  const handleUpdateBalance = () => {
    if (selectedUser) {
      const data = {
        key: selectedUser.key,
        balance: selectedUser.balance,
      };
      axios.post("https://Business.ozchest.com/updateSiteUserBalance", data).then((res) => {
        if (res) {
          toast.success('Balance will be updated after verification');
          closeModal();
        }
      });
    }
  };

  return (
    <div className="container mt-5">
      <div className="row balance-header">
        <div className="col-2 p2 home-heading">
          <h4 style={{ color: 'rgb(102 99 99)' }}>Ozchest Users</h4>
        </div>
      </div>
      <div className="table-responsive mt-4" style={{ minHeight: '60vh' }}>
        <table className="table table-striped">
        <thead style={{ backgroundColor: '#141414' }}>
            <tr>
              <th style={{ border: 'none' }} scope="col">#</th>
              <th style={{ border: 'none' }}>Name</th>
              <th style={{ border: 'none' }}>Email</th>
              <th style={{ border: 'none' }}>Address</th>
              <th style={{ border: 'none' }}>Balance</th>
              <th style={{ border: 'none' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => (
              <tr key={user._id}>
                <td style={{ border: 'none' }}>{index + 1}</td>
                <td style={{ border: 'none' }}>{user.name}</td>
                <td style={{ border: 'none' }}>{user.email}</td>
                <td style={{ border: 'none' }}>{user.address}</td>
                <td style={{ border: 'none' }}>
                  {user.balance}
                </td>
                <td style={{ border: 'none' }}>
                  <button
                    className="btn btn-primary"
                    style={{ color: '#b9b08', marginRight: '5px' }}
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-success ml-2"
                    style={{ color: '#b9b08' }}
                    onClick={() => openModal(user)} // Open the modal on clicking Update
                  >
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Update Balance Modal"
        className="modal-content"
      >
        <h2 style={{ marginBottom: '20px' }}>Update Balance</h2>
        {selectedUser && (
          <div className='modal-balance-div'>
            <label style={{ marginBottom: '10px' }}>New Balance (EUR):</label>
            <input
              type="number"
              value={selectedUser.balance}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({ ...prevUser, balance: e.target.value }))
              }
              className="balance-input"
            />
            <div className="modal-buttons">
              <button
                onClick={handleUpdateBalance}
                className="btn btn-success"
              >
                Update
              </button>
              <button
                onClick={closeModal}
                className="btn btn-danger"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SiteUsersInfo;

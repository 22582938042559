import React, { useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './PaystackSettings.css';

const PaystackSettings = () => {
  const [newKey, setnewKey] = useState('');
  const [confirmNewKey, setconfirmNewKey] = useState('');

  const handleChangeKey = () => {
    if (newKey === confirmNewKey) {
        if (newKey.length < 8) {
            toast.error('key must be atleast 8 characters long');
            return;
        }
        const data = {
            key: newKey,
        };
        axios.post("https://Business.ozchest.com/changePaystackKey", data).then((res) => {
        if (res) {
            setnewKey('');
            setconfirmNewKey('');
            toast.success('Key changed successfully!');
        }
        });
    } else {
      toast.error('Keys do not match. Please try again.');
    }
  };

  return (
    <div className="container history-container mt-5">
    <div className="row balance-header">
      <div className="col-2 p2 home-heading">
      <h4 style={{ color: 'rgb(102 99 99)' }}>Paystack</h4>
      </div>
      </div>
      <form className='pass-div'>
        <div className="mb-3">
          <label htmlFor="newKey" className="form-label">New Key</label>
          <input
            type="text"
            className="form-control pass-input"
            id="newKey"
            value={newKey}
            onChange={(e) => setnewKey(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmNewKey" className="form-label">Confirm New Key</label>
          <input
            type="text"
            className="form-control pass-input"
            id="confirmNewKey"
            value={confirmNewKey}
            onChange={(e) => setconfirmNewKey(e.target.value)}
          />
        </div>
        <button type="button" className="btn btn-primary" onClick={handleChangeKey}>
          Change Key
        </button>
      </form>
    </div>
  );
};

export default PaystackSettings;

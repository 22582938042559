import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHireContext } from '../../App.js';
import './Home.css';
import axios from 'axios';
import io from 'socket.io-client';

const Home = () => {
  const {
    balance,
    setBalance,
    isLoggedIn,
    email,
    apiStatus,
    setApiStatus,
    apiKey,
  } = useHireContext();
  const [socket, setSocket] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const newSocket = io('https://Business.ozchest.com');
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      socket?.on('updateBalance', (newMessage) => {
        if (newMessage.email === email) {
          sessionStorage.setItem('balance', newMessage.balance);
          setBalance(newMessage.balance);
        }
      });
    }
  });

  useEffect(() => {
    if (isLoggedIn) {
      const data = {
        email: email,
      };
      axios.post('https://Business.ozchest.com/getbalance', data).then((res) => {
        if (res) {
          setBalance(res.data.balance);
        }
      });
    }
  }, []);

  const handleToggleAccountStatus = () => {
    const data = {
      email: email,
      apiStatus: apiStatus === 'Active' ? 'Inactive' : 'Active',
    };
    axios.post('https://Business.ozchest.com/setApiStatus', data).then((res) => {
      if (res) {
        setApiStatus(data.apiStatus);
      }
    });
  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className='home-container mt-4'>
      <div className='row balance-header'>
        <div className='col-2 p2 home-heading'>
          <h4>Home</h4>
        </div>
        <div className='col'>
          <div className='p-2 balance-div'>
            <Link to='/topup' className='add-balance-btn btn btn-secondary'>
              Topup
            </Link>
            <div className='balance-box'>
              <h5>${balance?.toFixed(3)}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h5>Account Information</h5>
        </div>
      </div>
      <div className='row' style={{ marginRight: '20px' }}>
        <div className='col'>
          <div className='acc-info-div'>
            <div className='api-div'>
              <p>API Integration</p>
              <div className='key-div mr-4'>
              <input
                type='text'
                className='form-control'
                value={apiKey}
                readOnly
              />
              <button
                className='btn btn-secondary mt-2'
                onClick={handleCopyApiKey}
              >
                {copied ? 'Copied!' : 'Copy'}
              </button>
              </div>
            </div>
            <div>
              <p>Status</p>
              <p>
                {apiStatus}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import './App.css';
import { BrowserRouter, Route, Routes, Link, useNavigate } from 'react-router-dom';
import React, { createContext, useContext, useState, useEffect } from 'react';
import Sidebar from './Components/Sidebar/Sidebar';
import Order from './Components/Order/Order';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa6';
import ShoppingCarts from './Components/shoppingCarts/shoppingCarts';
import Cart from './Components/Cart/Cart';
import Login from './Components/Login/Login';
import Signup from './Components/Signup/Signup';
import Home from './Components/Home/Home';
import Topup from './Components/Topup/Topup';
import Finance from './Components/Finance/Finance';
import History from './Components/History/History';
import OrderHistory from './Components/OrderHistory/OrderHistory';
import TransactionHistory from './Components/TransactionHistory/TransactionHistory';
import UsersInfo from './Components/UsersInfo/UsersInfo';
import AdminSidebar from './Components/AdminSidebar/AdminSidebar';
import Settings from './Components/Settings/Settings';
import Discount from './Components/Discounts/Discounts';
import SiteUsersInfo from './Components/SiteUsers/SiteUsers';
import PricingComponent from './Components/UpdatePricing/UpdatePricing';
import PortalPricingComponent from './Components/UpdatePortalPricing/UpdatePortalPricing';
import PaystackSettings from './Components/PaystackSettings/PaystackSettings';
import UserSettings from './Components/UserSettings/UserSettings';
import AdminSettings from './Components/AdminSettings/AdminSettings';

const HireContext = createContext();

export const useHireContext = () => {
  return useContext(HireContext);
};

function Root() {
  const [cart, setCart] = useState([]);
  const [selectedCart, setSelectedCart] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem('token') ? true : false);
  const [isAdmin, setIsAdmin] = useState(sessionStorage.getItem('isAdmin') ? true : false);
  const [balance, setBalance] = useState(sessionStorage.getItem('balance') ? sessionStorage.getItem('balance') : 0);
  const [email, setEmail] = useState(sessionStorage.getItem('email') ? sessionStorage.getItem('email') : '');
  const [name, setName] = useState(sessionStorage.getItem('name') ? sessionStorage.getItem('name') : '');
  const [apiStatus, setApiStatus] = useState(true);
  const [apiKey, setApiKey] = useState(sessionStorage.getItem('apiKey') ? sessionStorage.getItem('apiKey') : '');

  const contextValue = {
    isAdmin,
    setIsAdmin,
    apiStatus,
    setApiStatus,
    cart,
    setCart,
    selectedCart,
    setSelectedCart,
    isLoggedIn,
    setIsLoggedIn,
    email,
    setEmail,
    balance,
    setBalance,
    name,
    setName,
    apiKey,
    setApiKey
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const handleCartDelete = (cartToDelete) => {
    const updatedCarts = cart.filter((cart) => cart !== cartToDelete);
    setCart(updatedCarts);
  };

  return (
      <HireContext.Provider value={contextValue}>
        {isLoggedIn ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
      </HireContext.Provider>
  );

  function AuthenticatedRoutes() {
    return (
      <>
        {isAdmin ? <AdminSidebar isActive={isActive} setIsActive={setIsActive} /> : <Sidebar isActive={isActive} setIsActive={setIsActive} />}
        <div className={`app-container ${isActive ? 'active-cont' : ''}`}>
          <a className="btn border-0" id="menu-btn" onClick={toggleSidebar}>
            {isActive ? <FaAngleLeft /> : <FaAngleRight />}
          </a>
          <Routes>
            <Route path="/" element={isLoggedIn ? (isAdmin ? <OrderHistory fromPortal={false}/> : <Home />) : <Login />} />
            <Route path="/portalOrders" element={<OrderHistory fromPortal={true}/>} />
            <Route path="/order" element={<Order />} />
            <Route path="/ShoppingCarts" element={<ShoppingCarts cart={cart} onDelete={handleCartDelete}/>} />
            <Route path="/ShoppingCart" element={<Cart selectedCart={selectedCart} deleteCart={handleCartDelete} />} />
            <Route path="/topup" element={<Topup />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/history" element={<History />} />
            <Route path="/siteTransactions" element={<TransactionHistory fromPortal={false}/>} />
            <Route path="/portalTransactions" element={<TransactionHistory fromPortal={true}/>} />
            <Route path="/portalUsers" element={<UsersInfo />} />
            <Route path="/users" element={<SiteUsersInfo />} />
            <Route path="/bitjem" element={<Settings />} />
            <Route path="/paystack" element={<PaystackSettings />} />
            <Route path="/discounts" element={<Discount />} />
            <Route path="/portalPricing" element={<PortalPricingComponent />} />
            <Route path="/ozchestPricing" element={<PricingComponent />} />
            <Route path="/createUser" element={<Signup />} />
            <Route path="/settings" element={<UserSettings />} />
            <Route path="/adminSettings" element={<UserSettings />} />
          </Routes>
        </div>
      </>
    );
  }

  function UnauthenticatedRoutes() {
    return (
      <Routes>
        <Route path="/" element={isLoggedIn ? <Order /> : <Login />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    );
  }
}

function App() {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

export default App;

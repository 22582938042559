import React, { useState } from 'react';
import './shoppingCarts.css';
import { useHireContext } from '../../App.js';
import { useNavigate } from 'react-router-dom';

const ShoppingCarts = ({ cart, onDelete }) => {
  const {setSelectedCart} = useHireContext();
  const navigate = useNavigate();

  const handleCartClick = (selectedCart) => {
    setSelectedCart(selectedCart);
    navigate('/shoppingCart');
  };

  const handleDeleteClick = (selectedCart) => {
    onDelete(selectedCart);
  };

  return (
    <div className="container">
      <h2 className="mt-4">Shopping Carts</h2>
      <table className="table table-hover mt-4">
        <thead className="thead-dark">
          <tr>
            <th>Date</th>
            <th>Client</th>
            <th>Brand</th>
            <th>Total Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {cart?.map((cart, index) => (
            <tr
              key={index}
              onClick={() => handleCartClick(cart)}
              className="cursor-pointer cart-row"
            >
              <td className='cart-td'>{cart.date}</td>
              <td className='cart-td'>{cart.client}</td>
              <td className='cart-td'>{cart.brand}</td>
              <td className='cart-td'>${cart?.totalPrice?.toFixed(3)}</td>
              <td>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the row click event from triggering
                    handleDeleteClick(cart);
                  }}
                  className="btn btn-danger btn-sm"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShoppingCarts;

import React, { useState } from "react";
import {useNavigate, Link} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Signup.css";
import { useHireContext } from '../../App.js';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
        setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "name") {
        setName(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!name || !email || !password) {
        toast.error("Please fill in all required fields");
        return;
      }
      if (password.length < 8) {
        toast.error("Password should be atleast 8 characters long");
        return;
      }
      axios
      .post("https://business.ozchest.com/signup", {
        header: { "Content-Type": "application/json" },
        data: {
          email: email,
          password: password,
          name: name
        },
      })
      .then((response) => {
        toast.success("Signup successful");
        setEmail('');
        setName('');
        setPassword('');
      }).catch((error) => {
        toast.error("Email already registered");
      });
  };

  return (
    <div className="container history-container mt-5">
    <div className="row balance-header">
      <div className="col-2 p2 home-heading">
      <h4 style={{ color: 'rgb(102 99 99)' }}>Create User</h4>
      </div>
      </div>
      <form className='pass-div' onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="newKey" className="form-label">Name</label>
          <input
            type="text"
            className="form-control pass-input"
            id="name"
            name="name"
            value={name}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmNewKey" className="form-label">Email</label>
          <input
            type="text"
            className="form-control pass-input"
            id="email"
            name="email"
            value={email}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmNewKey" className="form-label">Password</label>
          <input
            type="password"
            className="form-control pass-input"
            id="password"
            name="password"
            value={password}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Signup
        </button>
      </form>
    </div>
  );
};

export default Signup;

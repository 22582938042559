import React, {useState, useEffect} from 'react';
import altImg from '../../Assets/alt.jpg';
import './Cart.css';
import ConfirmationCart from '../ConfirmationCart/ConfirmationCart';
import { useHireContext } from '../../App.js';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Cart = ({ selectedCart, deleteCart }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const navigate = useNavigate();
  const {balance,
    email,
    setBalance,
    isLoggedIn
   } = useHireContext();
  // Calculate the total price of the cart
  // let totalPrice = selectedCart?.products?.reduce((total, product) => {
  //   return total + product.quantity * product.newPrice;
  // }, 0);

  useEffect(() => {
    if (isLoggedIn) {
      const data = {
        email: email,
      };
      axios.post("https://Business.ozchest.com/getbalance", data).then((res) => {   
        if (res) {
            setBalance(res.data.balance);
        } 
      });
    }
  },[]);

  const handleCheckout = () => {
    if (selectedCart?.brand === 'Bitjem') {
      setCheckoutLoading(true);
      if (balance >= selectedCart?.totalPrice) {
        const data = {
          currency: selectedCart?.products[0].name,
          amount: selectedCart?.products[0].faceValue,
          balance: balance,
          brand: selectedCart.brand,
          total: selectedCart?.totalPrice,
          email: email
        };
        axios
          .post("https://Business.ozchest.com/orderCrypto", data)
          .then((response) => {
            if (response) {
              localStorage.setItem("balance", balance - data.total);
              setBalance(balance - data.total);
              setCheckoutLoading(false);
              setShowConfirmation(false);
              deleteCart(selectedCart);
              navigate('/shoppingcarts');
              toast.success("Thanks For Buying");
            }
          });
      } else {
        setCheckoutLoading(false);
        toast.error("Not enough balance");
      }
    } else {
      setCheckoutLoading(true);
      if (selectedCart?.totalPrice !== 0 && balance >= selectedCart?.totalPrice) {
        const products = [];
        for (const product of selectedCart?.products) {
          products.push({
            ProductId: product.productId,
            Quantity: product.quantity,
            Value: Number(product.faceValue)
          })
        }
        const data = {
          products: products,
          user: email,
          balance: balance,
          brand: selectedCart.brand,
          total: selectedCart?.totalPrice,
          value: 7,
        };
        axios
          .post("https://Business.ozchest.com/order", data)
          .then((response) => {
            if (response) {
              localStorage.setItem("balance", balance - data.total);
              setBalance(balance - data.total);
              setCheckoutLoading(false);
              setShowConfirmation(false);
              deleteCart(selectedCart);
              navigate('/shoppingcarts');
              toast.success("Thanks For Buying");
            } else {
              setCheckoutLoading(false);
              toast.error("Something went wrong!");
            }
          }).catch((error) => {
            setCheckoutLoading(false);
            //console.log(error);
            toast.error(error?.response?.data);
          });
      } else {
        setCheckoutLoading(false);
        toast.error("Not enough balance");
      }
    }
  }

  const handleCheckoutClick = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="container">
      <h3>Cart Details</h3>
      <p>Date: {selectedCart.date}</p>
      <p>Client: {selectedCart.client}</p>
      <table className="table table-hover mt-4">
        <thead>
          <tr>
            <th>Brand</th>
            <th>Product Name</th>
            <th>Face Value</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {selectedCart?.products?.map((product, index) => (
            <tr key={index}>
              <td>
                <div className="brand-container">
                  <img
                      src={selectedCart.logoUrl !== "" ? selectedCart.logoUrl : altImg}
                      alt={altImg}
                      className="mr-2"
                  />
                </div>
              </td>
              <td>{product.name}</td>
              <td>{product.faceValue}</td>
              <td>{product.quantity}</td>
              <td>{selectedCart?.isCrypto ? (product.price) : (product.newPrice)?.toFixed(3)} {selectedCart?.isCrypto ? product.name : selectedCart.currencyCode}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="total-price-cart mt-4">
      <div className='price-div'>
            <h4>Total Price</h4>
            <span>
            ${selectedCart?.totalPrice?.toFixed(3)} USD
            </span>
        </div>
        <button className="btn btn-primary cart-checkout-btn" onClick={handleCheckoutClick}>Checkout</button>
      </div>
      {showConfirmation && (
        <ConfirmationCart
          selectedCart={selectedCart}
          totalPrice={selectedCart?.totalPrice}
          onCheckout={handleCheckout}
          onClose={handleCloseConfirmation}
          loading={checkoutLoading}
        />
      )}
    </div>
  );
};

export default Cart;

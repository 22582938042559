import React from 'react';
import './ConfirmationCart.css';
import altImg from '../../Assets/alt.jpg';

const ConfirmationCart = ({ selectedCart, totalPrice, onClose, onCheckout, loading }) => {
  return (
    <div className="popup-overlay">
    <div className="confirmation-cart">
      {loading ?
      <div className="cart-spinner-container">
      <div className="spinner-border cstm-spinner" role="status">
        <span className="sr-only"></span>
      </div>
    </div> :
    <>
      <h3>Confirm your order</h3>
      <div className='cart-info'>
        <p>Date: {selectedCart.date}</p>
        <p>Client: {selectedCart.client}</p>
      </div>
      <table className="table table-hover mt-4">
        <thead>
          <tr>
            <th>Brand</th>
            <th>Product Name</th>
            <th>Face Value</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {selectedCart.products.map((product, index) => (
            <tr key={index}>
            <td>
              <div className="brand-container">
                <img
                    src={selectedCart.logoUrl !== "" ? selectedCart.logoUrl : altImg}
                    alt={altImg}
                    className="mr-2"
                />
              </div>
            </td>
            <td><span className='popup-product-name'>{product.name}</span></td>
            <td>{product.faceValue}</td>
            <td>{product.quantity}</td>
            <td>{selectedCart?.isCrypto ? (product.price) : (product.price.min).toFixed(3)} {selectedCart?.isCrypto ? product.name : selectedCart.currencyCode}</td>
          </tr>
          ))}
        </tbody>
      </table>
      <div className="total-price-popup mt-4">
        <div className='price-div'>
            <h4>Total Price</h4>
            <span>
            ${totalPrice.toFixed(3)} USD
            </span>
        </div>
        <div className='confirmation-btns'>
            <button className="btn btn-primary" style={{marginRight: '10px'}} onClick={onCheckout}>Checkout</button>
            <button className="btn btn-secondary" onClick={onClose}>Cancel</button>
        </div>
      </div>
      </>
    }
    </div>
    </div>
  )
};

export default ConfirmationCart;

import React, { useState, useEffect } from 'react';
import { useHireContext } from '../../App.js';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Topup.css';
import {Link} from 'react-router-dom';
import io from 'socket.io-client';
import Paystack from '../Paystack/Paystack.js';

const Topup = () => {
    const [topup, setTopup] = useState(0);
    const {balance,
           email,
           setBalance,
           isLoggedIn
          } = useHireContext();

    const [socket, setSocket] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('nowpayment');

    useEffect(() => {
        const newSocket = io('https://Business.ozchest.com');
        setSocket(newSocket);
        return () => {
          newSocket.disconnect();
        };
    },[]);

    useEffect(() => {
        if (isLoggedIn) {
            socket?.on('updateBalance', newMessage => {
            if (newMessage.email === email) {
                setBalance(newMessage.balance);
            }
            });
        }
    });

  const handleTopupChange = (event) => {
    setTopup(event.target.value);
  };

  const handleTopUp = () => {
        axios.post("https://Business.ozchest.com/topup", {
        header: { "Content-Type": "application/json" },
        data : JSON.stringify({
            price_amount: topup,
            price_currency: "usd",
            order_description: email,
            ipn_callback_url: "https://Business.ozchest.com/ipn",
            success_url: "https://nowpayments.io",
            cancel_url: "https://nowpayments.io"
            })
        })
        .then((response) => {
            if (response) {
                let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=500,height=500`;
                window.open(response.data.invoice_url, "test", params);
            }
        }).catch((error) => {
          toast.error("Error occurred while topup");
        });
  };

  return (
    <div className="dashboard">
        <div className='container'>
        <div className="row balance-header">
        <div className="col-2 p2 home-heading">
          <h4>Add Balance</h4>
        </div>
        <div className="col">
          <div className='p-2 balance-div'>
          <Link to="/topup" className="add-balance-btn btn btn-secondary">
                Topup
              </Link>
              <div className='balance-box'> 
                <h5>${balance.toFixed(3)}</h5>
                </div>
          </div>
        </div>
      </div>
      <div className="mt-4 form-group">
            <label>Payment Method:</label>
            <select
                className="form-control"
                value={selectedPaymentMethod}
                onChange={(e) => setSelectedPaymentMethod(e.target.value)}
            >
                <option value="nowpayment">Nowpayment</option>
                <option value="paystack">Paystack</option>
            </select>
        </div>
            <div className="topup-container">
                <div className="balance-topup">
                    <div className="mt-4 form-group">
                        <label>Topup:</label>
                        <input
                            type="number"
                            min="0"
                            className="form-control"
                            id="topupInput"
                            value={topup}
                            onChange={handleTopupChange}
                        />
                    </div>
                    {selectedPaymentMethod === 'nowpayment' ? (
                    <button className="btn btn-primary" onClick={handleTopUp}>
                        Top Up
                    </button>
                    ) : (
                        <Paystack amount={Number(topup)}/>
                    )}
                </div>
            </div>
        </div>
  </div>
  );
};

export default Topup;

import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';

const OTPVerificationForm = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();
  const [timer, setTimer] = useState(120);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    // Start the countdown timer
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          setIsExpired(true);
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>Enter OTP:</label>
      <input type="text" {...register('otp', { required: true })} />

      <button type="submit" disabled={isExpired}>
        Verify OTP {isExpired ? '(Expired)' : `(${timer}s)`}
      </button>
    </form>
  );
};

export default OTPVerificationForm;

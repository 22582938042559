import React, { useEffect, useState } from 'react';
import altImg from '../../Assets/alt.jpg';
import cryptoCard from '../../Assets/cryptocard.jpg';
import './Order.css';
import { useHireContext } from '../../App.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
const jsonData = require("./Brands.json");

const Order = () => {
    const {cart, setCart} = useHireContext();
    const [brands, setBrands] = useState(jsonData.sort((a, b) => a.name.localeCompare(b.name)))
  const [selectedBrand, setSelectedBrand] = useState();
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [isCustomFaceValue, setIsCustomFaceValue] = useState(false);
  const [faceValue, setFaceValue] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);
  const [isCrypto, setIsCrypto] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState('BTC');
  const CryptoCurrencies = ['BTC', 'USDT'];

  const handleCryptoChange = (event) => {
    setSelectedCrypto(event.target.value);
  }

  const handleBrandChange = (event) => {
    setLoading(true);
    const selected = brands.find((brand) => brand.internalId === event.target.value);
    if (selected?.name === 'Bitjem') {
      setIsCrypto(true);
      setSelectedBrand(selected);
      setIsCustomFaceValue(true);
    } else {
      setSelectedBrand(selected);
      setProducts(selected?.products);
      setTotalPrice(0);
      if(selected.products[0].minFaceValue !== selected.products[0].maxFaceValue) {
        setIsCustomFaceValue(true);
        setCustomPrice(selected.products[0].price.min);
        setFaceValue(selected.products[0].minFaceValue);
      } else {
        setIsCustomFaceValue(false);
        setCustomPrice(0);
        setFaceValue(0);
      }
    }
    setInterval(()=> {
      setLoading(false);
    },1000);
  };

  const handleBrandChange2 = (event) => {
    setLoading(true);
    const selected = brands.find((brand) => brand.internalId === event.target.value);
    if (selected?.name === 'Bitjem') {
      setIsCrypto(true);
      setSelectedBrand(selected);
      setIsCustomFaceValue(true);
      setLoading(false);
    } else {
      const data = {
        brand: selected?.internalId
      }
      axios.post("https://Business.ozchest.com/getProducts", data).then((res) => {   
        if (res) {
          setSelectedBrand(selected);
          setProducts(res?.data.products);
          setTotalPrice(0);
          if(res?.data.products[0].minFaceValue !== res?.data.products[0].maxFaceValue) {
            setIsCustomFaceValue(true);
            setCustomPrice(res?.data.products[0].price.min);
            setFaceValue(res?.data.products[0].minFaceValue);
          } else {
            setIsCustomFaceValue(false);
            setCustomPrice(0);
            setFaceValue(0);
          }
          setLoading(false);
        } 
      });
    }
  };

  const handleProductChange = (productId, newQuantity) => {
    //setPriceLoading(true);
    const updatedProducts = products.map((product) => {
      if (product.productId === productId) {
        // Create a new object with the updated quantity and newPrice
        const updatedProduct = {
          ...product,
          quantity: newQuantity,
          newPrice: isCustomFaceValue ? customPrice : product.price.min,
          faceValue: isCustomFaceValue ? faceValue : product.minFaceValue,
          isCustomFaceValue: isCustomFaceValue
        };
        return updatedProduct;
      }
      return product; // For other products, return them unchanged
    });
    setProducts(updatedProducts);

    // Calculate the total price based on the updated quantities
    let newTotalPrice = updatedProducts.reduce((total, product) => {
      return total + ((product.quantity ?? 0) * (product.newPrice ?? 0));
    }, 0);
    setTotalPrice(newTotalPrice);
  //   const data2 = {
  //     from: selectedBrand.currencyCode,
  //     to: "USD",
  //     amount: newTotalPrice,
  //     value: 6,
  //   };
  // axios
  //   .post("https://Business.ozchest.com/convertRate", data2)
  //   .then((response) => {
  //     setTotalPrice(response.data.cur);
  //     setPriceLoading(false);
  //   });
  };

  const handleAddToCart = () => {
    if (isCrypto) {
      if (faceValue === 0) {
        toast.error('Please enter face value');
        return;
      }
      const date = new Date();
      let currentDay= String(date.getDate()).padStart(2, '0');
      let currentMonth = String(date.getMonth()+1).padStart(2,"0");
      let currentYear = date.getFullYear();
      let currentDate = `${currentDay}/${currentMonth}/${currentYear}`;

      const data2 = {
        currency: selectedCrypto,
        amount: totalPrice,
        };
         axios
        .post("https://Business.ozchest.com/convertCrypto", data2)
        .then((response) => {
          let products = [
            {
              name: selectedCrypto,
              faceValue: faceValue,
              quantity: 1,
              price: faceValue,
            }
          ];
    
          let currentCart = {
            date: currentDate,
            client: 'Nexoz',
            brand: selectedBrand.name,
            isCrypto: true,
            currencyCode: products[0]?.price?.currencyCode ?? selectedBrand.currencyCode,
            logoUrl: cryptoCard,
            totalPrice: response.data.cur,
            products: products
          }
          setCart(cart => [...cart, currentCart]);
        });
      toast.success('Items successfully added to cart');
    } else {
      // Filter products with quantity > 0 and add them to the cart
      const tempProducts = products.filter((product) => product.quantity > 0 && product.count === 0);
      if (tempProducts?.length !== 0) {
        toast.error(`${tempProducts[0].name} out of stock`);
        return;
      }
      const selectedProducts = products.filter((product) => product.quantity > 0);
      
      const date = new Date();
      let currentDay= String(date.getDate()).padStart(2, '0');
      let currentMonth = String(date.getMonth()+1).padStart(2,"0");
      let currentYear = date.getFullYear();
      let currentDate = `${currentDay}/${currentMonth}/${currentYear}`;
  
      const data2 = {
      from: products[0]?.price?.currencyCode ?? selectedBrand.currencyCode,
      to: "USD",
      amount: totalPrice,
      value: 6,
      };
       axios
      .post("https://Business.ozchest.com/convertRate", data2)
      .then((response) => {
        let currentCart = {
          date: currentDate,
          client: 'Nexoz',
          brand: selectedBrand.name,
          currencyCode: products[0]?.price?.currencyCode ?? selectedBrand.currencyCode,
          logoUrl: selectedBrand.logoUrl,
          totalPrice: response.data.cur,
          products: selectedProducts
      }
      setCart(cart => [...cart, currentCart]);
      // Reset the quantities of selected products to 0
      const updatedProducts = products.map((product) =>
        product.quantity > 0 ? { ...product, quantity: 0 } : product
      );
      setProducts(updatedProducts);
      setTotalPrice(0);
      toast.success('Items successfully added to cart');
      });
    }
  };

  const handleFaceValueChange = (e) => {
    setFaceValue(e.target.value);
    if(!isCrypto) {
      let product = products[0];
      let price = (product.price.min/product.minFaceValue)*e.target.value;
      setCustomPrice(price);
      let totalPrice = price*product.quantity;
      totalPrice = totalPrice ? totalPrice : 0;
      setTotalPrice(totalPrice);
    } else {
      setTotalPrice(Number(e.target.value));
    }
  }

  const [searchTerm, setSearchTerm] = useState('');

  const [showDropdown, setShowDropdown] = useState(false);

  // Function to handle the search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle dropdown visibility
  const handleDropdownVisibility = () => {
    setShowDropdown(!showDropdown);
  };

  // Function to handle the selection of a brand
  const handleBrandSelect = (brandId) => {
    handleBrandChange2({ target: { value: brandId } });
    setShowDropdown(false);
    setSearchTerm(''); // Clear the search term after selection
  };

  // Filter brands based on the search term
  const filteredBrands = brands?.filter((brand) =>
    brand.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 brand-col">
        <input
        type="text"
        placeholder="Search or choose a brand"
        value={searchTerm !== '' ? searchTerm : selectedBrand?.name}
        onChange={handleSearchChange}
        onFocus={handleDropdownVisibility}
        className="form-control"
        style={{ width: '100%' }}
      />
      {showDropdown && (
        <div className="col-md-3 dropdown-menu-custom">
          <ul className="cstm-list list-unstyled m-0 p-0">
            {filteredBrands.length > 0 ? (
              filteredBrands.map((brand) => (
                <li
                  key={brand.internalId}
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() => handleBrandSelect(brand.internalId)}
                >
                  {brand.name}
                </li>
              ))
            ) : (
              <li className="p-2">No brands found</li>
            )}
          </ul>
        </div>
      )}

          <div className="brand-card mt-3">
          {loading ? (
            <div className="spinner-border" role="status">
            </div>
          ) : (
            <>
            <div className='image-card'>
            <label className='mb-2'>Brand Image</label>
            <img src={isCrypto ? cryptoCard : (selectedBrand?.logoUrl ? selectedBrand?.logoUrl : altImg)} alt={altImg} className="img-fluid" />
            </div>
            <div className='currency-card'>
                <label className='mb-2'>Currency</label>
                <span>{isCrypto ? selectedCrypto : selectedBrand?.currencyCode}</span>
            </div>
            </>
          )}
          </div>
        </div>
        <div className="col-md-7">
        {loading ? (
            <div className="spinner-border" role="status">
            </div>
          ) : (
          <table className="table products-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Count</th>
                <th>Price</th>
                {isCustomFaceValue ? (
                 <th>Face Value</th>
                 ) : ''}
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {!isCrypto ? products?.map((product) => (
                <tr key={product.productId} className='product-row'>
                  <td>{product.name + (isCustomFaceValue ? (" " + product.minFaceValue+"-"+product.maxFaceValue):"")}</td>
                  <td>{product.count === null ? 1000 : product.count}</td>
                  <td>
                    {isCustomFaceValue ? (customPrice)?.toFixed(3) : (product.price.min).toFixed(3)} {product.price?.currencyCode}
                  </td>
                  {isCustomFaceValue ? 
                  <td>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control text-center"
                        min={product.minFaceValue}
                        max={product.maxFaceValue}
                        value={faceValue}
                        onChange={(e) => handleFaceValueChange(e)}
                      />
                    </div>
                  </td> : ''}
                  <td>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control text-center"
                        min={0}
                        max={product.count === null ? 100 : product.count}
                        value={product.quantity ?? 0}
                        onChange={(e) => {
                          const newQuantity = parseInt(e.target.value, 10);
                          if (!isNaN(newQuantity)) {
                            handleProductChange(product.productId, newQuantity);
                          }
                        }}
                      />
                    </div>
                  </td>
                </tr>
                )):
                <tr key={selectedBrand?.internalId} className='product-row'>
                  <td>
                  <select className="form-select" onChange={handleCryptoChange}>
                    {CryptoCurrencies?.map((curr, index) => (
                      <option key={index} value={curr}>
                        {curr}
                      </option>
                    ))}
                  </select>
                  </td>
                  <td>1</td>
                  <td>
                    
                  </td>
                  {isCustomFaceValue ? 
                  <td>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control text-center"
                        min="0"
                        max="1000"
                        value={faceValue}
                        onChange={(e) => handleFaceValueChange(e)}
                      />
                    </div>
                  </td> : ''}
                  <td>
                    1
                  </td>
                </tr>
                }
            </tbody>
          </table>
          )}
          <div className='add-to-cart'>
            <div className="total-price">
            {priceLoading ? <div className="spinner-border" role="status">
            </div> : 
            <div className='price-div'>
              <span>Total Price</span>
            <span>
            ${totalPrice.toFixed(3)} {isCrypto ? selectedCrypto : products[0]?.price?.currencyCode}
            </span>
            </div>
        }
            </div>
            <button className={
              (!isCrypto && totalPrice === 0) ? 'btn btn-primary disabled' : 'btn btn-primary'
            } onClick={handleAddToCart}>Add to Cart</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;

import React, {useState, useEffect} from 'react';
import './TransactionHistory.css';
import axios from "axios";

const TransactionHistory = ({fromPortal}) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const data = {
      fromPortal: fromPortal
    };
    axios.post("https://Business.ozchest.com/getAllTransactions", data).then((res) => {   
      if (res) {
        setTransactions(res.data.transactions);
      } 
    });
  }, [fromPortal]);

  return (
    <div className="container history-container mt-5" >
      <div className="row balance-header">
      <div className="col-2 p2 home-heading">
      <h4 style={{ color: 'rgb(102 99 99)' }}>Transactions</h4>
      </div>
      </div>
      <div className="table-responsive mt-4">
        <table className="table">
          <thead className="fw-bold" style={{ backgroundColor: '#141414' }}>
            <tr>
              <th style={{ border: 'none' }} scope="col">#</th>
              <th style={{ border: 'none' }} scope="col">User</th>
              <th style={{ border: 'none' }} scope="col">Type</th>
              <th style={{ border: 'none' }} scope="col">Total Amount</th>
              <th style={{ border: 'none' }} scope="col">Date</th>
              <th style={{ border: 'none' }} scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((order, index) => (
              <tr key={order._id}>
                <td style={{ border: 'none', textAlign: 'center' }}>{index + 1}</td>
                <td style={{ border: 'none', textAlign: 'center' }}>{order.user}</td>
                <td style={{ border: 'none', textAlign: 'center' }}>{order.type}</td>
                <td style={{ border: 'none', textAlign: 'center' }}>${order.totalAmount}</td>
                <td style={{ border: 'none', textAlign: 'center' }}>${order.date}</td>
                <td style={{ border: 'none', textAlign: 'center' }} className={`project-status ${order.status.toLowerCase()}`}>
                    {order.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionHistory;
